export const IMAGES = {
	'info.png': require('./background/info.png'),
	'auto.png': require('./icons/auto.png'),
	'compulsory.png': require('./icons/compulsory.png'),
	'work-waiting.png': require('./icons/work-waiting.png'),
	'tax.png': require('./icons/tax.png'),
	'info-customer.png': require('./icons/info-customer.png'),
	'success.png': require('./icons/success.png'),
	'wait_pay.png': require('./icons/wait_pay.png'),
	'commission-standart.png': require('./commission/commission-standart.png'),
	'commission-debit.png': require('./commission/commission-debit.png'),
	'commission-quick.png': require('./commission/commission-quick.png'),
	'info-1.png': require('./background/info-1.png'),
	'info-2.png': require('./background/info-2.png'),
	'info-3.png': require('./background/info-3.png'),
	'head-info-3.png': require('./background/head-info-3.png'),
	'bg-payment.png': require('./background/bg-payment.png'),
	'bg-thaiqr.png': require('./background/bg-thaiqr.png'),
	'bg-header-login.png': require('./background/header-login.png'),
	'footer-tel.png': require('./footer/tel.png'),
	'footer-facebook.png': require('./footer/facebook.png'),
	'footer-line.png': require('./footer/line.png'),
	'logo-fin.png': require('./logo/logo-fin.png'),
	'menu-check-car.png': require('./menu/check-car.png'),
	'menu-check-insure.png': require('./menu/check-insure.png'),
	'menu-check-prb.png': require('./menu/check-prb.png'),
	'menu-check-quo.png': require('./menu/check-quo.png'),
	'menu-renew-insure.png': require('./menu/renew-insure.png'),
	'menu-check-tax.png': require('./menu/check-tax.png'),
	'img-login-img.png': require('./img/login-img.png'),
	'winwheel/arrow.png': require('./winwheel/arrow.png'),
	'winwheel/arrow2.png': require('./winwheel/arrow2.png'),
	'winwheel/lottery.png': require('./winwheel/lottery.png'),
	'winwheel/ticket-outline.png': require('./winwheel/ticket-outline.png'),
	'winwheel/cart-outline.png': require('./winwheel/cart-outline.png'),
  'bg-winwheel.png': require('./background/bg-winwheel.png'),
  'bg-head-winwheel.png': require('./background/bg-head-winwheel.png'),
  'bg-footer-winwheel.png': require('./background/bg-footer-winwheel.png'),
	'winwheel/lotto-prb.png': require('./winwheel/lotto-prb.png'),
	'winwheel/lotto-2+.png': require('./winwheel/lotto-2+.png'),
	'winwheel/lotto-3+.png': require('./winwheel/lotto-3+.png'),
	'winwheel/lotto-3.png': require('./winwheel/lotto-3.png'),
	'winwheel/LOT0002.png': require('./winwheel/LOT0002.png'),
	'winwheel/LOT0001.png': require('./winwheel/LOT0001.png'),
	'winwheel/LOT0004.png': require('./winwheel/LOT0004.png'),
	'winwheel/LOT0005.png': require('./winwheel/LOT0005.png'),
	'winwheel/LOT0003.png': require('./winwheel/LOT0003.png'),
	'winwheel/giftbox.jpg': require('./winwheel/giftbox.jpg'),
}
