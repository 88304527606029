import React, { useEffect, useRef, useState } from 'react'
import WinwheelSetting from 'winwheel'
import { TweenMax } from 'gsap'
import { IMAGES } from '../../themes'
import { Box } from '..'

export const Winwheel = ({ targetSegment, onCallBack }) => {
	const canvasRef = useRef(null)
	const [theWheel, setTheWheel] = useState(null)
	const [wheelSpinning, setWheelSpinning] = useState(false)

	useEffect(() => {
		window.TweenMax = TweenMax

		// Initialize the wheel when the component mounts
		const wheel = new WinwheelSetting({
			numSegments: 8,
			outerRadius: 150,
			drawMode: 'image',
			animation: {
				type: 'spinToStop',
				duration: 5,
				spins: 8,
			},
		})

		// Load wheel image
		const loadedImg = new Image()
		loadedImg.onload = () => {
			wheel.wheelImage = loadedImg
			wheel.draw()
		}
		loadedImg.src = IMAGES['winwheel/lottery.png']

		setTheWheel(wheel)
	}, [])

	useEffect(async () => {
		if (targetSegment) {
			startSpin()
			onCallBack()
		}
	}, [targetSegment])

	const startSpin = () => {
		if (!wheelSpinning && theWheel) {
			theWheel.animation.spins = 6

			const segmentAngle = 360 / theWheel.numSegments
			const stopAngle = 360 - targetSegment * segmentAngle + segmentAngle / 2

			theWheel.animation.stopAngle = stopAngle
			theWheel.startAnimation()
			setWheelSpinning(true)
			setTimeout(() => {
				stopSpin()
			}, 6000)
		}
	}

	const stopSpin = () => {
		theWheel.stopAnimation(false)
		theWheel.rotationAngle = 0
		setWheelSpinning(false)
    console.log('stopSpin')
	}

	return (
		<Box>
			<canvas ref={canvasRef} id='canvas' width='400' height='400'>
				<p>Your browser doesn't support canvas. Please try another.</p>
			</canvas>
		</Box>
	)
}
