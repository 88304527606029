import apiService from '../apiService'

const apiPath = '/coupon'

export const couponController = (configService) => {
	const service = apiService(configService)
	return {
		getPointLotto: () => {
			return service.get({
				url: `${apiPath}/get-point-lotto`,
			})
		},
		getItemsLotto: (numTicket) => {
			return service.get({
				url: `${apiPath}/get-items-lotto?numTicket=${numTicket}`,
			})
		},
		getRewardsLotto: () => {
			return service.get({
				url: `${apiPath}/get-Rewards-lotto`,
			})
		},
	}
}
